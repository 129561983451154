// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCwfaFI2DwHGMOJh79guYo7ufC3yaa-n0Q",
  authDomain: "portfolio-67948.firebaseapp.com",
  projectId: "portfolio-67948",
  storageBucket: "portfolio-67948.firebasestorage.app",
  messagingSenderId: "1045084795006",
  appId: "1:1045084795006:web:9739751a8f7cccbe51682f",
  measurementId: "G-QZXJ28XKZL"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);